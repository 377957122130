@font-face {
  font-family: "raleway";
  src: local("raleway"), url(./fonts/Raleway-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "JosefinSlab";
  src: local("JosefinSlab"),
    url(./fonts/JosefinSlab-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "NunitoSans";
  src: local("NunitoSans"),
    url(./fonts/NunitoSans-Regular.ttf) format("truetype");
}

#toolbar-container .ql-font span[data-label="Raleway"]::before {
  font-family: "raleway";
}

#toolbar-container .ql-font span[data-label="JosefinSlab"]::before {
  font-family: "JosefinSlab";
}

#toolbar-container .ql-font span[data-label="NunitoSans"]::before {
  font-family: "NunitoSans";
}
#toolbar-container .ql-font span[data-label="Sans Serif"]::before {
  font-family: "Sans Serif";
}

.ql-font-josefinslab {
  font-family: "JosefinSlab";
}

.ql-font-nunitosans {
  font-family: "NunitoSans";
}

html {
  position: relative;
  min-height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "raleway", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "sans-serif";
  background-color: #eee;
  margin: 70px 0 70px 0;
  padding: 0;
}
.container {
  margin: 80px auto 80px auto;
  max-width: 1300px;
  min-height: 665px;
}

.nav-container {
  padding: 0;
  width: "100%";
}
.nav-container svg {
  color: #fff;
}
a {
  text-decoration: none;
}
#footer {
  width: 100%;
  margin: auto;
  background-color: #4c4c4c;
  padding-top: 30px;
  padding-bottom: 15px;
  position: relative;
  bottom: 0;
  display: inline-block;
}
#footer p {
  color: #fff;
  text-align: center;
  line-height: 2em;
  font-size: 0.9em;
}

#footer a {
  color: #fff;
  text-decoration: none;
}

.ql-editor iframe {
  min-height: 300px;
}

.ql-editor img {
  max-height: "100%";
  object-fit: cover;
}

.ql-syntax {
  background-color: #f9f9f9; /* Light subtle gray */
  border-left: 4px solid #dcdcdc; /* Optional, to add a visual left border */
  padding: 8px 12px; /* Add some padding for better readability */
  border-radius: 4px; /* Rounded corners */
  color: #333; /* Darker text for contrast */
  font-family: monospace; /* Monospace font for code */
  overflow-x: auto; /* Allow horizontal scrolling for long lines */
}

.ql-syntax {
  background-color: #e0e0e0; /* Light gray background */
  color: #333; /* Dark text */
  padding: 10px;
  border-radius: 4px;
  font-family: monospace;
}
